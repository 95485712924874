export const getSocialSlugByUrl = (url: string): string | null => {
  if (url.startsWith("https://www.instagram.com") || url.startsWith("https://instagram.com")) {
    return "instagram";
  } else if (url.startsWith("https://www.facebook.com") || url.startsWith("https://facebook.com")) {
    return "facebook";
  } else if (url.startsWith("https://www.tiktok.com") || url.startsWith("https://tiktok.com")) {
    return "tiktok";
  } else if (url.startsWith("https://www.youtube.com") || url.startsWith("https://youtube.com")) {
    return "youtube";
  } else if (
    url.startsWith("https://www.twitter.com") ||
    url.startsWith("https://twitter.com") ||
    url.startsWith("https://www.x.com") ||
    url.startsWith("https://x.com")
  ) {
    return "twitter";
  } else if (url.startsWith("https://www.discord.com") || url.startsWith("https://discord.com") || url.startsWith("https://www.discord.gg") || url.startsWith("https://discord.gg")) {
    return "discord";
  } else if (url.startsWith("https://www.twitch.tv") || url.startsWith("https://twitch.tv")) {
    return "twitch";
  }

  return null;
};
