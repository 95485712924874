import { Game, GameSocial } from "../../../../api/games/game.model";
import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Tr,
  useToast,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import { useSocials } from "../../../../hooks/useSocials";
import { DeleteIcon, EditIcon, PlusSquareIcon } from "@chakra-ui/icons";
import { AddSocialToGameModal } from "./components/AddSocialToGameModal/AddSocialToGameModal";
import { UpdateGameSocialModal } from "./components/UpdateGameSocialModal/UpdateGameSocialModal";
import { gameService } from "../../../../api/games/game.service";
import { AddSocialsFromRawHTMLModal } from "./components/AddSocialsFromRawHTMLModal/AddSocialsFromRawHTMLModal";

type Props = {
  game: Game;
  refetchGame: () => Promise<void>;
  updateMemoryGame: (updateFn: (prev: Game) => Game) => any;
};

const COMPONENT_ID = "GameSocialsTab";

export const GameSocialsTab = (props: Props) => {
  const toast = useToast();
  const socialsData = useSocials();

  const addSocialToGameModalRefController = useRef<AddSocialToGameModal["RefController"]>({} as any);
  const addSocialToGameFromRawHTMLModalRefController = useRef<AddSocialsFromRawHTMLModal["FRefController"]>({} as any);
  const updateGameSocialModalRefController = useRef<UpdateGameSocialModal["RefController"]>({} as any);

  const [localGameSocials, setLocalGameSocials] = useState<GameSocial[]>(props.game.socials);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);

  const addSocialsFromHTML: AddSocialsFromRawHTMLModal["Props"]["onSave"] = (data) => {
    const socialsToSave: GameSocial[] = [];

    for (const socialFromHTML of data) {
      socialsToSave.push({
        _id: socialFromHTML.social._id,
        slug: socialFromHTML.social.slug,
        name: socialFromHTML.social.name,
        url: socialFromHTML.url,
      });
    }

    for (const socialFromLocal of localGameSocials) {
      const alreadyExists = socialsToSave.find((social) => social._id === socialFromLocal._id);

      if (alreadyExists) {
        continue;
      }

      socialsToSave.push({
        ...socialFromLocal,
      });
    }

    setLocalGameSocials(socialsToSave);
    addSocialToGameFromRawHTMLModalRefController.current.close();
  };

  const addSocialToGame: AddSocialToGameModal["Props"]["onSave"] = (data) => {
    setLocalGameSocials((prev) => [
      ...prev,
      {
        _id: data.social._id,
        name: data.social.name,
        slug: data.social.slug,
        url: data.url,
      },
    ]);
    addSocialToGameModalRefController.current.close();
  };

  const removeSocialFromGame = (gameSocial: GameSocial) => {
    setLocalGameSocials((prev) => prev.filter((element) => element._id !== gameSocial._id));
  };

  const updateGameSocial: UpdateGameSocialModal["Props"]["onSave"] = (nextGameSocialId: string, url: string) => {
    setLocalGameSocials((prev) =>
      prev.map((element) => {
        if (element._id !== nextGameSocialId) {
          return element;
        }

        return {
          ...element,
          url,
        };
      })
    );
  };

  useEffect(() => {
    props.updateMemoryGame((prev) => ({
      ...prev,
      socials: localGameSocials,
    }));
  }, [localGameSocials]);

  const openAddSocialsModal = () => {
    if (!addSocialToGameModalRefController.current) {
      return;
    }

    addSocialToGameModalRefController.current.open();
  };

  const save = async () => {
    setSaveLoading(true);

    const socialsUpdateList = localGameSocials.map((social) => ({ socialId: social._id, url: social.url }));

    try {
      await gameService.updateSocials(props.game._id, socialsUpdateList);
      toast({
        title: "Game Socials were successfully created",
        status: "success",
      });

      props.refetchGame().catch(() =>
        toast({
          title: "Could not refetch Game, please reload a page",
          status: "warning",
        })
      );
    } catch (err) {
      toast({
        title: "Unexpected error. Please reload page and try again",
        status: "error",
      });
    }

    setSaveLoading(false);
  };

  return (
    <Box data-cn={COMPONENT_ID}>
      <Flex columnGap={2}>
        <Button
          leftIcon={<PlusSquareIcon />}
          colorScheme="teal"
          variant="outline"
          onClick={openAddSocialsModal}
          size="sm"
        >
          Add Social Manually
        </Button>
        <Button
          leftIcon={<PlusSquareIcon />}
          colorScheme="facebook"
          variant="outline"
          onClick={addSocialToGameFromRawHTMLModalRefController.current.open}
          size="sm"
        >
          Add Social From Raw HTML
        </Button>
      </Flex>
      <TableContainer my={4}>
        <Divider />
        <Table size="sm">
          <Tbody>
            {localGameSocials.map((gameSocial) => (
              <Tr key={gameSocial._id}>
                <Td width="5%">
                  <Box>{gameSocial.name}</Box>
                </Td>
                <Td width="5%">
                  <Box>{gameSocial.url}</Box>
                </Td>
                <Td width="5%" textAlign="center" paddingX={0}>
                  <IconButton
                    aria-label="Edit game"
                    variant="ghost"
                    size="sm"
                    onClick={() => updateGameSocialModalRefController.current?.open(gameSocial)}
                    icon={<EditIcon color="blue" />}
                  />
                </Td>
                <Td width="5%" textAlign="center" paddingX={0}>
                  <IconButton
                    aria-label={""}
                    variant="ghost"
                    size="sm"
                    onClick={() => removeSocialFromGame(gameSocial)}
                    icon={<DeleteIcon color="red" />}
                  />
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </TableContainer>
      <Box>
        <Button
          variant={"outline"}
          colorScheme={"green"}
          onClick={save}
          isLoading={saveLoading}
          isDisabled={saveLoading}
        >
          Save
        </Button>
      </Box>
      <AddSocialToGameModal
        ref={addSocialToGameModalRefController}
        allSocialsList={socialsData.list}
        currentSocialList={localGameSocials}
        onSave={addSocialToGame}
      />
      <AddSocialsFromRawHTMLModal
        ref={addSocialToGameFromRawHTMLModalRefController}
        allSocialsList={socialsData.list}
        currentSocialList={localGameSocials}
        onSave={addSocialsFromHTML}
      />
      <UpdateGameSocialModal ref={updateGameSocialModalRefController} onSave={updateGameSocial} />
    </Box>
  );
};
