import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { GameSocial } from "../../../../../../api/games/game.model";

type RefController = {
  open: (gameSocial: GameSocial) => void;
  clear: () => void;
  close: () => void;
};

type Props = {
  onSave: (gameSocialId: string, url: string) => void;
};

export const UpdateGameSocialModal = forwardRef((props: Props, ref: ForwardedRef<RefController>) => {
  const modalState = useDisclosure();

  const gameSocialToUpdateRef = useRef<GameSocial | null>(null);

  const [urlInputError, setUrlInputError] = useState<null | string>(null);
  const urlInputRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    open: (gameSocial) => {
      modalState.onOpen();
      gameSocialToUpdateRef.current = gameSocial;

      setTimeout(() => {
        if (!urlInputRef.current) {
          return;
        }

        urlInputRef.current.value = gameSocial.url;
      }, 0);
    },
    close: () => {
      clear();
      modalState.onClose();
    },
    clear,
  }));

  const clear = () => {
    if (!urlInputRef.current) {
      return;
    }

    setUrlInputError(null);
    urlInputRef.current.value = "";
  };

  const handleClose = () => {
    modalState.onClose();
    clear();
  };

  const handleUrlChange = (nextUrl: string) => {
    setUrlInputError(null);

    if (!gameSocialToUpdateRef.current) {
      return;
    }

    const originalOrigin = new URL(gameSocialToUpdateRef.current.url).origin;

    if (!nextUrl.startsWith(originalOrigin)) {
      setUrlInputError("Provided URL does not belongs to original Social");
      return;
    }
  };

  const handleSave = () => {
    if (!urlInputRef.current) {
      return;
    }

    const url = urlInputRef.current.value;

    if (!url.trim().length) {
      setUrlInputError("Cannot be empty");
      return;
    }

    try {
      new URL(url);
    } catch {
      setUrlInputError("Provided URL is not url");
      return;
    }

    if (!gameSocialToUpdateRef.current) {
      return;
    }

    props.onSave(gameSocialToUpdateRef.current._id, url);
    modalState.onClose();
    clear();
  };

  return (
    <Modal isOpen={modalState.isOpen} onClose={handleClose}>
      <input ref={urlInputRef} />
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Social</ModalHeader>
        <ModalBody>
          <FormControl isDisabled>
            <FormLabel>Social</FormLabel>
            <Select>
              <option value={gameSocialToUpdateRef.current?._id}>{gameSocialToUpdateRef.current?.name}</option>
            </Select>
          </FormControl>
          <FormControl mt={2} isInvalid={typeof urlInputError === "string"}>
            <FormLabel>URL</FormLabel>
            <Input ref={urlInputRef} type="text" onChange={(e) => handleUrlChange(e.target.value)} />
            <FormErrorMessage>{urlInputError}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button onClick={handleClose}>Close</Button>
            <Button variant={"outline"} colorScheme={"green"} onClick={handleSave}>
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export type UpdateGameSocialModal = {
  Props: Props;
  RefController: RefController;
};
