import axios, { AxiosResponse } from "axios";
import { Article, ArticleAttachment, ArticleBelongingType, ArticlePublishStatus, ArticleType } from "./article.model";
import { endpoints } from "./endpoints";
import { PagRes } from "../shared/pagRes.model";

type Create = {
  title: string;
  shortDescription: string;
  readTime: number;
  rawHtml: string;
  type: ArticleType;
  belongsTo: {
    type: ArticleBelongingType;
    id: string;
  };
  cover: {
    key: string;
    gradient: string;
  };
  attachments: [];
};

type Update = {
  title: string;
  shortDescription: string;
  readTime: number;
  rawHtml: string;
  attachments: (Omit<ArticleAttachment, "_id"> & Partial<Pick<ArticleAttachment, "_id">>)[];
  keywords: string[];
  cover: {
    _id?: string;
    key: string;
    gradient: string;
  };
};

type UpdatePublishStatus = {
  publishStatus: ArticlePublishStatus;
};

export class ArticleService {
  static create = async (data: Create): Promise<AxiosResponse<Article>> => {
    return axios.post(endpoints.create.insert(), data);
  };

  static retrieveAll = async (
    pagination: { take: number; skip: number } & Record<string, any>
  ): Promise<AxiosResponse<PagRes<Article>>> => {
    return axios.get(endpoints.retrieveAll.insert(), { params: pagination });
  };

  static retrieveById = async (articleId: string): Promise<AxiosResponse<Article>> => {
    return axios.get(endpoints.retrieveOne.insert({ articleId }));
  };

  static updateById = async (articleId: string, dto: Update): Promise<AxiosResponse<Article>> => {
    return axios.put(endpoints.retrieveOne.insert({ articleId }), dto);
  };

  static updatePublishStatus = async (articleId: string, dto: UpdatePublishStatus): Promise<AxiosResponse<Article>> => {
    return axios.post(endpoints.updatePublishStatus.insert({ articleId }), dto);
  };
}
