import { GameSocial } from "../../../../../../api/games/game.model";
import { Social } from "../../../../../../api/socials/social.model";
import {
  Box,
  Button,
  Code,
  Flex,
  HStack,
  Kbd,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ForwardedRef, forwardRef, useImperativeHandle, useState } from "react";
import { getSocialSlugByUrl } from "../../get-social-slug-by-url";

type FRefController = {
  open: () => void;
  close: () => void;
};

type Props = {
  allSocialsList: Social[];
  currentSocialList: GameSocial[];
  onSave: (data: { social: Social; url: string }[]) => void;
};

type ParsedSocial = {
  slug: string;
  url: string;
  conflictsWith?: GameSocial;
};

const COMPONENT_ID = "AddSocialsFromRawHTMLModal";

// cw:component
export const AddSocialsFromRawHTMLModal = forwardRef((props: Props, ref: ForwardedRef<FRefController>) => {
  const modalState = useDisclosure();

  const [socialsToBeAddedOrUpdated, setSocialsToBeAddedOrUpdated] = useState<ParsedSocial[] | null>(null);

  useImperativeHandle(ref, () => ({
    open: () => {
      modalState.onOpen();
    },
    close: () => {
      modalState.onClose();
      clear();
    },
  }));

  const clear = () => {
    setSocialsToBeAddedOrUpdated(null);
  };

  const handleClose = () => {
    modalState.onClose();
    clear();
  };

  const handleSave = () => {
    if (socialsToBeAddedOrUpdated === null) {
      return;
    }

    const haveConflicts = socialsToBeAddedOrUpdated.find((elem) => !!elem.conflictsWith);

    if (haveConflicts) {
      return;
    }

    props.onSave(
      socialsToBeAddedOrUpdated.map((elem) => ({
        url: elem.url,
        social: props.allSocialsList.find((social) => social.slug === elem.slug) as Social,
      }))
    );
  };

  const handlePast = async () => {
    const rawHTML = await navigator.clipboard.readText();

    const hrefRegex = /<a[^>]+href="([^"]+)"/g;

    const urls = [];
    let match;

    // Get all links from pasted HTML
    while ((match = hrefRegex.exec(rawHTML)) !== null) {
      urls.push(match[1]);
    }

    const parsedSocials: ParsedSocial[] = [];

    for (const url of urls) {
      const slug = getSocialSlugByUrl(url);

      if (slug === null) {
        continue;
      }

      const existedSocial = props.currentSocialList.find((elem) => elem.slug === slug);

      if (existedSocial) {
        if (existedSocial.url === url) {
          continue;
        }

        parsedSocials.push({
          url,
          slug,
          conflictsWith: existedSocial,
        });

        continue;
      }

      parsedSocials.push({ url, slug });
    }

    setSocialsToBeAddedOrUpdated(parsedSocials);
  };

  const resolveConflict = (referenceToArrayElement: ParsedSocial, resolve: "clipboard" | "original") => {
    setSocialsToBeAddedOrUpdated((prev) => {
      if (prev === null) {
        return null;
      }

      if (resolve === "clipboard") {
        return prev.map((elem) => {
          if (elem === referenceToArrayElement) {
            return {
              url: referenceToArrayElement.url,
              slug: referenceToArrayElement.slug,
            };
          }

          return elem;
        });
      }

      if (resolve === "original") {
        return prev.filter((elem) => elem !== referenceToArrayElement);
      }

      return prev;
    });
  };

  return (
    <Modal data-cn={COMPONENT_ID} isOpen={modalState.isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Social</ModalHeader>
        <ModalBody>
          {socialsToBeAddedOrUpdated !== null && socialsToBeAddedOrUpdated.length === 0 && (
            <Box mb={2} textAlign={"center"}>
              <Text>No socials were found from your clipboard.</Text>
            </Box>
          )}
          {(socialsToBeAddedOrUpdated === null || socialsToBeAddedOrUpdated.length === 0) && (
            <Flex justifyContent={"center"} alignItems={"center"}>
              <Button variant={"outline"} colorScheme={"green"} onClick={handlePast}>
                Past From Clipboard
              </Button>
            </Flex>
          )}
          {socialsToBeAddedOrUpdated !== null && socialsToBeAddedOrUpdated.length > 0 && (
            <Flex flexDirection={"column"} rowGap={2}>
              {socialsToBeAddedOrUpdated.map((socialToBeAddOrUpdate) => {
                return (
                  <Box
                    key={socialToBeAddOrUpdate.slug}
                    background={socialToBeAddOrUpdate.conflictsWith ? "red.400" : "transparent"}
                    borderRadius={3}
                    p={socialToBeAddOrUpdate.conflictsWith ? 2 : 0}
                  >
                    <Box>
                      {socialToBeAddOrUpdate.conflictsWith && <Box mb={3}><Text fontWeight={"bold"}>Conflict</Text></Box>}
                      {socialToBeAddOrUpdate.conflictsWith && (
                        <Flex justifyContent={"space-between"}>
                          <Tag mb={1} colorScheme={"cyan"}>
                            From your clipboard
                          </Tag>
                          <Button
                            size={"xs"}
                            colorScheme={"green"}
                            onClick={() => resolveConflict(socialToBeAddOrUpdate, "clipboard")}
                          >
                            Use from clipboard
                          </Button>
                        </Flex>
                      )}
                      <Flex flexDirection={"column"} rowGap={1} columnGap={2} overflow={"hidden"}>
                        <Kbd width={"fit-content"}>{socialToBeAddOrUpdate.slug}</Kbd>
                        <Code>{socialToBeAddOrUpdate.url}</Code>
                      </Flex>
                      {socialToBeAddOrUpdate.conflictsWith && (
                        <Box mt={3}>
                          <Flex justifyContent={"space-between"}>
                            <Tag mb={1} colorScheme={"yellow"}>
                              From current game socials
                            </Tag>
                            <Button
                              size={"xs"}
                              colorScheme={"green"}
                              onClick={() => resolveConflict(socialToBeAddOrUpdate, "original")}
                            >
                              Keep Original
                            </Button>
                          </Flex>
                          <Flex flexDirection={"column"} rowGap={1} columnGap={2} overflow={"hidden"}>
                            <Kbd width={"fit-content"}>{socialToBeAddOrUpdate.conflictsWith.slug}</Kbd>
                            <Code>{socialToBeAddOrUpdate.conflictsWith.url}</Code>
                          </Flex>
                        </Box>
                      )}
                    </Box>
                  </Box>
                );
              })}
            </Flex>
          )}
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button onClick={handleClose}>Close</Button>
            <Button variant={"outline"} colorScheme={"green"} onClick={handleSave}>
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export type AddSocialsFromRawHTMLModal = {
  Props: Props;
  FRefController: FRefController;
};
