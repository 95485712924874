import { Box, Button, FormControl, FormErrorMessage, FormLabel, Input, useToast } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import axios from "axios";

export const CacheManagementPage = () => {
  const toast = useToast();

  const [inputError, setInputError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const inputElementRef = useRef<HTMLInputElement>({} as any);

  const handleInvalidation = async () => {
    if (inputElementRef.current.value.trim() === "") {
      setInputError("Cannot be empty");
      return;
    }

    try {
      new URL(inputElementRef.current.value);
    } catch (err) {
      setInputError("You should have a valid URL");
      return;
    }

    try {
      await axios.post("/api/v1/admin/copilot-cache-invalidation-request", {
        urlToInvalidate: inputElementRef.current.value,
      });

      toast({
        title: "Cache invalidation success",
        status: "success",
      });
    } catch (err) {
      toast({
        title: "Cache invalidation error",
        status: "error",
      });
    }
  };

  return (
    <Box>
      <FormControl mt={2} isInvalid={inputError !== null}>
        <FormLabel>Url to invalidate</FormLabel>
        <Input maxWidth={600} ref={inputElementRef} type="text" onChange={() => setInputError(null)} />
        <FormErrorMessage>{inputError}</FormErrorMessage>
      </FormControl>
      <Button mt={3} colorScheme={"red"} onClick={() => handleInvalidation()} isLoading={loading}>
        Invalidate
      </Button>
    </Box>
  );
};
