import {
  Box,
  Button,
  Code,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Tag,
  useDisclosure,
} from "@chakra-ui/react";
import React, { ForwardedRef, forwardRef, useImperativeHandle, useRef, useState } from "react";
import { Social } from "../../../../../../api/socials/social.model";
import { getSocialSlugByUrl } from "../../get-social-slug-by-url";
import { GameSocial } from "../../../../../../api/games/game.model";

type RefController = {
  open: () => void;
  clear: () => void;
  switchLoading: (next: boolean) => void;
  close: () => void;
};

type Props = {
  allSocialsList: Social[];
  currentSocialList: GameSocial[];
  onSave: (data: { social: Social; url: string }) => void;
};

const NO_MATCH_VALUE = "no-match";

export const AddSocialToGameModal = forwardRef((props: Props, ref: ForwardedRef<RefController>) => {
  const modalState = useDisclosure();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const socialsSelectRef = useRef<HTMLSelectElement>({} as any);

  const [urlInputError, setUrlInputError] = useState<null | string>(null);
  const urlInputRef = useRef<HTMLInputElement>({} as any);

  useImperativeHandle(ref, () => ({
    open: () => {
      modalState.onOpen();
    },
    close: () => {
      modalState.onClose();
      clear();
    },
    clear,
    switchLoading: (next) => setIsLoading(next),
  }));

  const clear = () => {
    if (!urlInputRef.current) {
      return;
    }

    setUrlInputError(null);
    urlInputRef.current.value = "";
  };

  const handleClose = () => {
    modalState.onClose();
    clear();
  };

  const handleURLChange = (nextUrl: string) => {
    setUrlInputError(null);

    const slug = getSocialSlugByUrl(nextUrl);

    if (slug === null) {
      socialsSelectRef.current.value = NO_MATCH_VALUE;
      return;
    }

    const socialAlreadyExists = props.currentSocialList.find((gameSocial) => gameSocial.slug === slug);

    if (socialAlreadyExists) {
      socialsSelectRef.current.value = NO_MATCH_VALUE;
      setUrlInputError("Social already exists for this game");
      return;
    }

    socialsSelectRef.current.value = slug;
  };

  const handleSave = () => {
    const socialSlug = socialsSelectRef.current.value;
    const url = urlInputRef.current.value;

    if (!url.trim().length) {
      setUrlInputError("Cannot be empty");
      return;
    }

    try {
      new URL(url);
    } catch {
      setUrlInputError("Provided URL is not url");
      return;
    }

    if (!socialSlug.trim().length) {
      return;
    }

    const social = props.allSocialsList.find((element) => element.slug === socialSlug);

    if (!social) {
      return;
    }

    props.onSave({
      social,
      url,
    });
  };

  const socialListAvailableForSelect = props.allSocialsList.filter((social) => {
    return !props.currentSocialList.find((localSocial) => localSocial._id === social._id);
  });

  return (
    <Modal isOpen={modalState.isOpen} onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Create Social</ModalHeader>
        <ModalBody>
          <FormControl>
            <FormLabel>Social</FormLabel>
            <Select ref={socialsSelectRef} disabled>
              <option value={NO_MATCH_VALUE}>No Social Found</option>
              {socialListAvailableForSelect.map((option) => {
                return (
                  <option key={option._id} value={option.slug}>
                    {option.name}
                  </option>
                );
              })}
            </Select>
          </FormControl>
          <Box mt={2}>
            <Box mb={1}>
              <Code colorScheme="yellow" children="Globally supported socials" />
            </Box>
            <Flex gap={1} flexWrap={"wrap"}>
              {props.allSocialsList.map((social) => (
                <Tag key={social.slug}>{social.name}</Tag>
              ))}
            </Flex>
          </Box>
          <FormControl mt={2} isInvalid={typeof urlInputError === "string"}>
            <FormLabel>URL</FormLabel>
            <Input ref={urlInputRef} type="text" onChange={(e) => handleURLChange(e.target.value)} />
            <FormErrorMessage>{urlInputError}</FormErrorMessage>
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <HStack spacing={2}>
            <Button onClick={handleClose}>Close</Button>
            <Button
              variant={"outline"}
              colorScheme={"green"}
              onClick={handleSave}
              isLoading={isLoading}
              isDisabled={isLoading}
            >
              Save
            </Button>
          </HStack>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export type AddSocialToGameModal = {
  Props: Props;
  RefController: RefController;
};
